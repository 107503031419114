@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "styles/variables";
@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";

html {
  height: 100%;
}
body {
  //padding-right: 0px!important;
  // padding-top: 70px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-height: 100%;
}

@media (min-width: 992px) {
  body {
    // padding-top: 70px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 900px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

a {
  color: $main_color;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

select {
  border-radius: 2px;
}

.padding-none {
  padding: 0;
}

#your_request {
  padding-top: 50px;
  padding-bottom: 100px;
  img {
    width: 100px;
    margin: 50px 0;
  }
}

.loading-bg {
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.loader {
  color: #ffcb00;
  font-size: 14px;
  margin: 300px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.intl-tel-input {
  position: relative;
  display: block;

  .country-list {
    .country {
      padding: 5px 10px;
      white-space: normal;
    }
  }
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.block {
  display: block;
}

.card {
  display: block;
}

input::-ms-clear {
  display: none;
}

.margin-bottom {
  margin-bottom: 1em;
}



ngb-datepicker {
  box-shadow: 0px 2px 0.75rem rgba(12, 14, 28, 0.08);
  border-radius: 8px !important;
  border: 0 !important;
}

.ngb-dp-weekday {
  color: #0065b8 !important;
  font-style: normal !important;
  font-weight: 700;
}

ngb-datepicker {
  righ: calc(100% - 472px) !important;
  margin-top: 5px !important;
}

@media (max-width: 767px) {
  .depart-rt ngb-datepicker {
    left: calc(100% - 241px) !important;
  }
  .return-rt ngb-datepicker {
    left: calc(100% - 234px) !important;
  }
  #one-way-form ngb-datepicker,
  #multi-city-form ngb-datepicker {
    left: 0 !important;
  }
}

@media (max-width: 576px) {
  .depart-rt ngb-datepicker {
    left: 0 !important;
  }
  .return-rt ngb-datepicker {
    left: calc(100% - 233px) !important;
  }
  #noty_layout__bottomRight,
  #noty_layout__topCenter {
    .noty_bar {
      display: block !important;
      .noty_buttons {
        display: block !important;
      }
    }
  }
}

// Colors for invalid fields
form {
  .has-error {
    //color: #dd4b39 !important;
    background-color: #fff3f3 !important;
  }
  .has-error:focus {
    border-bottom: 3px solid #dd4b39 !important;
    box-shadow: 0 0 0 0 rgba(255, 12, 0, 0.25) !important;
  }

  #roundTripDepart.has-error {
    background-color: #fff3f3;
    color: #dd4b39 !important;
  }
  #roundTripReturn.has-error {
    background-color: #fff3f3;
    color: #dd4b39 !important;
  }
}

.form-control::-webkit-input-placeholder {
  color: #c7c6c6;
} /* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: #c7c6c6;
} /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: #c7c6c6;
} /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: #c7c6c6;
} /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: #c7c6c6;
} /* Microsoft Edge */

form {
  .has-error {
    //color: #dd4b39 !important;
    background-color: #fff !important;
    border: 2px solid #ff0000 !important;
  }
  .has-error:focus {
    border-bottom: 3px solid #dd4b39 !important;
    box-shadow: 0 0 0 0 rgba(255, 12, 0, 0.25) !important;
  }

  #roundTripDepart.has-error {
    background-color: #fff3f3;
    color: #dd4b39 !important;
  }
  #roundTripReturn.has-error {
    background-color: #fff3f3;
    color: #dd4b39 !important;
  }
}

// Color for disabled components
.form-control:disabled {
  background-color: #e9ecef !important;
}

.disabled-control {
  background-color: #e9ecef !important;
  cursor: default !important;
}

.how-it-works-dialog {
  .mat-dialog-container {
    max-width: none;
    padding: 0;
  }
}
/* modal animation */
.modal-content {
  animation-name: modal-show;
  animation-duration: 0.4s;
}

.modal-backdrop {
  opacity: 0.8!important;
}

@keyframes modal-show {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
@keyframes backdrop-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
.hiw-backdrop {
  z-index: 10060 !important;
  animation-name: backdrop-show;
  animation-duration: 0.4s;
  //opacity: 0!important;
}
.hiw-modal {
  text-align: center;
  button {
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    background: none; // remove it to correct position in header
    //margin-top: 20px;
    //margin-bottom: 40px;
    //padding: 0;
    @media (max-width: 767px) {
      & {
        margin-top: 0px;
        margin-bottom: 25px;
        padding-top: 0;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.call-me-dialog {
  //.mat-dialog-container {
  //  max-width: none;
  //  padding: 0;
  //  border-radius: .3rem;
  //}
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.9;
}
//.write-review-dialog {
//  margin-top: 2%;
//}
.mat-dialog-container {
  padding: 0px !important;
  border-radius: 0.3rem !important;
  z-index: 10060 !important;
}

.cdk-overlay-container {
  z-index: 1050;
}

.modal {
  top: 3.5em;
}
#noty_layout__topCenter {
  top: 0;
  margin-top: 10px;
}

#noty_layout__bottomRight,
#noty_layout__topCenter {
  width: 600px;
  .noty_bar {
    display: flex;
    justify-content: center;
    align-items: center;
    .noty_buttons {
      border-top: none !important;
      display: inline-flex;
      button {
        background-color: transparent;
        font-weight: 600;
        font-size: 16px;
        &:focus {
          box-shadow: none;
        }
      }
      .apply-update {
        color: #0065b8;
        &:hover {
          background-color: rgba(63, 81, 181, 0.08);
        }
      }
      .dismiss-update {
        color: #f50057;
        &:hover {
          background-color: rgba(245, 0, 87, 0.08);
        }
      }
    }
  }
  .noty_type__warning {
    color: #332a09;
  }
}
.cdk-overlay-container {
  z-index: 10055 !important;
}
.modal {
  z-index: 10060 !important;
}

app-searchbox-kayak-banner {
  .intl-tel-input {
    .selected-flag {
      padding: 0 0 0 13px !important;
      .iti-flag {
        margin-top: 33px !important;
      }
      .iti-arrow {
        margin-top: 8px !important;
        right: 0px !important;
      }
    }
  }
}
app-searchbox-kayak {
  .intl-tel-input {
    .selected-flag {
      padding: 0 0 0 13px !important;
      .iti-flag {
        margin-top: 35px !important;
      }
      .iti-arrow {
        margin-top: 10px !important;
        right: 0px !important;
      }
    }
  }
}
app-trustpilot-box.kayak section#trustpilot-box {
  padding-top: 40px !important;
  padding-bottom: 0px !important;
}

.before-close-dialog {
  .modal-dialog {
    max-width: 600px;
    .modal-content {
      background-color: #3f51b5;
      border: 0;
      border-radius: 3px;
      padding-left: 70px;
      padding-right: 70px;
    }
  }
}

.intl-tel-input.separate-dial-code .selected-flag {
  background-color: transparent;
}

.cruise-options {
  &.mat-autocomplete-panel {
    max-height: 400px;
  }

  .mat-option {
    height: 40px;
  }
}

.mat-autocomplete-panel.cruise-dropdown-full-with {
  min-width: fit-content;
}

.cursor-pointer {
  cursor: pointer;
}

.cdk-overlay-pane:has(.dropdown-left-border) {
  display: flex;
  justify-content: flex-end;
  .dropdown-left-border {
    min-width: fit-content;
  }
}

@media (max-width: 767px) {
  .before-close-dialog .modal-dialog .modal-content {
    padding-left: 0;
    padding-right: 0;
  }

}

@media (max-width: 767px) {
  .before-close-dialog form .bottom-form a {
    font-size: 12px;
    margin-left: 20px;
  }

}

.searchbox-down-sections-indents {
  padding-left: 10%;
  padding-right: 10%;
  margin-left: 0;
  margin-right: 0;
}
